import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { GET_LIST, GET_ONE, NumberInput, RecordContextProvider, useLocale, useQuery, useTranslate } from 'react-admin'
import { LinearProgress, Table, TableBody as MUITableBody, TableCell, TableHead, TablePagination, TableRow, makeStyles, TableSortLabel, Tooltip, TableContainer, Typography } from '@material-ui/core'
import { orderBy } from 'lodash'
import { blue } from '@material-ui/core/colors'

import { translateApiProperty } from '@hisports/common'
import { groupBy } from '@hisports/common/src/lodash'

import NoResults from '../../common/NoResults'
import FunctionField from '../../common/fields/FunctionField'
import { SquareAlert } from '../../common/SquareAlert';

import { PoolField } from '../pools/PoolField'

import { getGenericLocale } from '../../locale/LocaleProvider'

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingInline: theme.spacing(0.75),
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  poolHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'whitesmoke',
  }
}))

const getPropertyValue = (row, property) => row.stats ? row.stats[property.id] : row[property.id]

const getSchemaProperties = (schema, rows, querySort) => {
  if (!schema?.schema?.properties) return [];

  const properties = Object.keys(schema.schema.properties)
    .map(id => ({ id, ...schema.schema.properties[id] }))
    .filter(property => {
      let { hidden, hideNull } = property.displayOptions || {};
      let missingRowProperty = false;
      if (hidden) return false;

      if (querySort && querySort.includes(property.id)) {
        hideNull = false;
      } else {
        missingRowProperty = rows.every(row => {
          const rowProperties = Object.keys(row.stats || row);
          return !rowProperties.includes(property.id);
        });
      }

      if (missingRowProperty) return false;

      return !(hideNull && rows.every(row => getPropertyValue(row, property) == null))
    })

  properties.sort((a, b) => a.displayOptions.order - b.displayOptions.order);
  return properties;
}

const formatPrecision = (stat, precision) => {
  return Number(stat).toFixed(precision);
}

const formatPercentage = (stat) => {
  if (stat >= 1) return stat;
  const [ , decimal ] = String(stat).split('.');
  return `.${decimal}`
}

const formatPlusMinus = (stat) => {
  const style = {
    color: stat === 0 ? undefined : (stat < 0 ? 'red' : 'green'),
    fontSize: 'inherit',
  };

  return <Typography style={style}>{stat <= 0 ? stat : `+${stat}`}</Typography>;
};

const formatMinutes = (stat) => {
  const minutes = Math.floor(stat);
  const seconds = Math.round((stat % 1) * 60)
  return `${minutes}:${String(seconds).padStart(2, 0)}`
}

const format = (value, displayOptions) => {
  const { precision, type } = displayOptions;

  if (value == null) return '--'

  if (value === true) return '✓';

  if (precision) {
    value = formatPrecision(value, precision);
  }

  switch (type) {
    case 'percentage': return formatPercentage(value);
    case 'plus-minus': return formatPlusMinus(value);
    case 'minutes': return formatMinutes(value);
  }

  return value;
}

const sortStats = (data, sortBy, sort, standingsType) => {
  if (standingsType === 'Overall') {
    return orderBy(data, [sortBy], [sort])
  }

  const groupedData = groupBy(data, 'poolId');
  return Object.values(groupedData).flatMap(poolData =>
    orderBy(poolData, [sortBy], [sort])
  )
}

const filterStats = (row, filters) => {
  const isTeam = filters?.teamId == null || row.teamId === filters.teamId;
  const isGroup = filters?.groupId == null || row.groupId === filters.groupId;
  const isPool = filters?.poolId == null || row.poolId === filters.poolId;
  return isTeam && isGroup && isPool;
}

const useScheduleStats = (resource, filter) => useQuery({
  type: GET_LIST,
  resource,
  payload: {
    filter,
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: !!resource })

const useSchema = schemaId => useQuery({
  type: GET_ONE,
  resource: 'schemas',
  payload: { id: schemaId }
}, { enabled: !!schemaId })

const RankHeader = ({ sort, sortBy, createSortHandler, translate, property = 'ranking', hasManualRanking }) => {
  return <TableCell component="th" scope="row" align="center" style={{ backgroundColor: property === sortBy ? blue[50] : undefined, width: 75 }}>
    <TableSortLabel
      active={property === sortBy}
      direction={sortBy === property ? sort : 'asc'}
      onClick={createSortHandler(property)}
      hideSortIcon
    >
      {translate(`components.scheduleStats.labels.${property}${hasManualRanking ? '_og' : ''}`)}
    </TableSortLabel>
  </TableCell>
}

const RankCell = ({ row, standingsType, schedule, sortBy, property = 'ranking', isFormOpen = false }) => {
  const value = row[property];
  const poolId = (standingsType === 'Pools' && row?.poolId !== null) ? row.poolId : 'Overall';

  return <TableCell component="th" scope="row" align="center" style={{ backgroundColor: property === sortBy ? blue[50] : undefined, paddingBlock: isFormOpen ? 0 : undefined }}>
    <RecordContextProvider value={{ [property]: value || '-', ...schedule }}>
      {isFormOpen
        ? <NumberInput source={`${poolId}_${row.id}`} defaultValue={value} variant="outlined" label={false} options={{ InputLabelProps: { shrink: false } }} helperText="" min={1} />
        : <FunctionField source={property} render={record => record[property]} />
      }
    </RecordContextProvider>
  </TableCell>
}

const SortableHeader = ({ properties, renderHeaders, onRequestSort, hidden }) => {
  const { filters, sort, sortBy, manualRanking, manualPoolRanking, isFormOpen } = useScheduleStatsContext();
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  const genericLocale = getGenericLocale(locale);

  const showManualRanking = manualRanking || manualPoolRanking || isFormOpen;
  const manualRankingProperty = manualPoolRanking ? 'manualPoolRanking' : 'manualRanking';
  const rankingProperty = filters?.standingsType === 'Pools' ? 'poolRanking' : 'ranking';

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  if (hidden) return null;
  return <TableHead>
    <TableRow>
      {(showManualRanking) && <RankHeader sort={sort} sortBy={sortBy} createSortHandler={createSortHandler} translate={translate} property={manualRankingProperty} />}
      <RankHeader sort={sort} sortBy={sortBy} createSortHandler={createSortHandler} translate={translate} property={rankingProperty} hasManualRanking={showManualRanking} />
      {renderHeaders && renderHeaders({ sort, sortBy, createSortHandler, translate })}
      {properties.map(property => {
        const { id, displayOptions } = property;
        const description = translateApiProperty(displayOptions, 'description', genericLocale);
        const abbreviation = translateApiProperty(displayOptions, 'abbreviation', genericLocale);
        const active = id === sortBy;

        return <TableCell key={id} sortDirection={sortBy === id ? sort : false} align="center" style={{ backgroundColor: sortBy === id ? blue[50] : undefined }}>
          <Tooltip title={description} placement="top">
            <TableSortLabel className={classes.label} active={active} direction={sortBy === id ? sort : 'asc'} onClick={createSortHandler(id)} hideSortIcon>
              {abbreviation}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      })}
    </TableRow>
  </TableHead>
}

const TableBody = ({ data, properties, renderColumns, fixedColumns }) => {
  const { filters, sortBy, schedule, manualRanking, manualPoolRanking, isFormOpen } = useScheduleStatsContext();

  const showManualRanking = manualRanking || manualPoolRanking || isFormOpen;
  const manualRankingProperty = manualPoolRanking ? 'manualPoolRanking' : 'manualRanking';
  const rankingProperty = filters?.standingsType === 'Pools' ? 'poolRanking' : 'ranking';

  if (!data?.length) return <TableRow>
    <TableCell colSpan={properties.length + (fixedColumns || 0) + (manualRanking ? 1 : 0)}>
      <NoResults />
    </TableCell>
  </TableRow>

  return <MUITableBody>
    {data.map((row) => (
      <TableRow key={row.id}>
        {showManualRanking && <RankCell row={row} standingsType={filters?.standingsType} schedule={schedule} sortBy={sortBy} property={manualRankingProperty} isFormOpen={isFormOpen} />}
        <RankCell row={row} standingsType={filters?.standingsType} schedule={schedule} sortBy={sortBy} property={rankingProperty} />
        {renderColumns && renderColumns(row, schedule, sortBy)}
        {properties.map(property => {
          const { id, displayOptions } = property;
          const stat = format(getPropertyValue(row, property), displayOptions);
          return <TableCell key={id} align="center" style={{ backgroundColor: sortBy === id ? blue[50] : undefined }}>{stat}</TableCell>
        })}
      </TableRow>
    ))}
  </MUITableBody>
}

const TableContent = ({ data, properties, renderColumns, renderHeaders, handleRequestSort }) => {
  const { resource, filters } = useScheduleStatsContext();
  const translate = useTranslate();
  const classes = useStyles();

  const shouldGroupStats = resource === 'TeamScheduleStats' && filters.standingsType === 'Pools';

  const groupedData = shouldGroupStats
    ? groupBy(data, 'poolId')
    : { Overall: data }

  return Object.keys(groupedData)
    .sort((a, b) => {
      const nameA = groupedData[a][0]?.pool?.name;
      const nameB = groupedData[b][0]?.pool?.name;

      // Always sort "Not in Pool" to the end
      if (!nameA) return 1;
      if (!nameB) return -1;

      return nameA.localeCompare(nameB);
    })
    .map(poolId => {
      const poolData = groupedData[poolId];
      return <>
        {shouldGroupStats && <div className={classes.poolHeader}>
          {poolId === 'null' ?
            <Typography variant="h6">{translate('components.scheduleStats.labels.no_pools')}</Typography> :
            <RecordContextProvider value={{ poolId }}>
              <PoolField source="poolId" variant="h6" />
            </RecordContextProvider>}
        </div>
        }
        <Table className={classes.table}>
          <SortableHeader properties={properties} renderHeaders={renderHeaders} onRequestSort={handleRequestSort} hidden={!poolData?.length} />
          <TableBody data={poolData} properties={properties} renderColumns={renderColumns} />
        </Table>
      </>
    })
}

const Filters = ({ filterComponents = [] }) => {
  const classes = useStyles();
  const { groupIds, poolIds } = useScheduleStatsContext();

  const hasFilters = filterComponents.some(filter => {
    if (filter.key === 'group') return groupIds?.length > 0;
    if (filter.key === 'standings-type') return poolIds?.length > 0;
    return true;
  });

  if (!hasFilters) return null;
  return <div className={classes.filtersContainer}>
    {filterComponents}
  </div>
}

const ScheduleStatsContext = createContext();

export const useScheduleStatsContext = () => useContext(ScheduleStatsContext);

export const ScheduleStatsTable = ({
  resource,
  filter: defaultFilters = {},
  filters: filterComponents,
  sort: defaultSort,
  renderColumns,
  renderHeaders,
  schedule,
  isFormOpen,
  triggerRefetch,
  disablePagination,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState(defaultSort?.field || 'id');
  const [sort, setSort] = useState(defaultSort?.order || 'asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(disablePagination ? 99999 : 25);
  const [filters, setFilters] = useState(defaultFilters);

  const { data, loading: dataLoading, refetch } = useScheduleStats(resource, {
    scheduleId: schedule?.id,
    type: filters?.type,
    ...(resource === 'TeamScheduleStats' ? { _include: ['pool'] } : {})
  });
  const { data: schema, loading: schemaLoading } = useSchema(data?.[0]?.schemaId);

  const filteredData = useMemo(() => data?.filter(row => filterStats(row, filters)), [data, filters]);
  const sortedData = useMemo(() => {
    const sortedStats = sortStats(filteredData, sortBy, sort, filters.standingsType);
    return sortedStats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, sortBy, sort, page, rowsPerPage, filters.standingsType]);

  const hasManualRanking = useMemo(() => data?.some(row => row.manualRanking != null) && filters?.standingsType === 'Overall', [data, filters.standingsType]);
  const hasManualPoolRanking = useMemo(() => data?.some(row => row.manualPoolRanking != null) && filters?.standingsType === 'Pools', [data, filters.standingsType]);

  useEffect(() => {
    if (!triggerRefetch) return;
    refetch();
  }, [refetch, triggerRefetch]);

  useEffect(() => {
    // reset to initial sort
    setSortBy(defaultSort?.field || 'id');
    setSort(defaultSort?.order || 'asc');

    if (hasManualRanking || hasManualPoolRanking) {
      setSortBy(hasManualRanking ? 'manualRanking' : 'manualPoolRanking');
      setSort('asc');
    } else if (filters?.standingsType === 'Pools') {
      setSortBy('poolRanking');
      setSort('asc');
    }
  }, [hasManualRanking, hasManualPoolRanking, filters?.standingsType, defaultSort?.field, defaultSort?.order]);

  const properties = getSchemaProperties(schema, data);

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && sort === 'desc';
    const isRankingProperty = property.toLowerCase().includes('ranking');
    const newSort = isRankingProperty && sortBy !== property ? 'asc' : (isDesc ? 'asc' : 'desc');

    setSort(newSort);
    setSortBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const context = useMemo(() => ({
    resource,
    schedule,
    teamIds: data?.map(row => row.teamId).filter(Boolean),
    groupIds: data?.map(row => row.groupId).filter(Boolean),
    poolIds: data?.map(row => row.poolId).filter(Boolean),
    manualPoolRanking: hasManualPoolRanking,
    manualRanking: hasManualRanking,
    isFormOpen,
    page,
    sort,
    sortBy,
    filters,
    setPage,
    setSort,
    setSortBy,
    setFilters
  }), [resource, data, schedule, filters, page, sortBy, sort, hasManualRanking, hasManualPoolRanking, isFormOpen]);

  const loading = dataLoading || schemaLoading;
  const showManualRankingAlert = hasManualRanking || hasManualPoolRanking || isFormOpen;

  return (
    <ScheduleStatsContext.Provider value={context}>
      {loading
        ? <LinearProgress />
        : <>
          {showManualRankingAlert && <SquareAlert severity="warning">{translate('components.scheduleStats.alerts.manual_standings')}</SquareAlert>}
          <Filters filterComponents={filterComponents} />
          <TableContainer className={classes.tableContainer}>
            <TableContent
              data={sortedData}
              properties={properties}
              renderColumns={renderColumns}
              renderHeaders={renderHeaders}
              handleRequestSort={handleRequestSort}
            />
          </TableContainer>
          {!disablePagination && <TablePagination
            className={classes.pagination}
            count={filteredData?.length}
            page={page}
            rowsPerPageOptions={[10, 25, 50]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </>}
    </ScheduleStatsContext.Provider>
  )
}
