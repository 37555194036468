const messages = {
  Hockey: {
    resources: {
      surfaces: {
        name: 'Patinoire |||| Patinoires',
        fields: {
          venueId: 'Aréna'
        },
        values: {
          size: {
            XL: 'Olympique',
            M: 'LNH',
            XS: '3 contre 3',
          },
        },
        actions: {
          add: 'Ajouter une patinoire',
          edit: 'Modifier la patinoire',
        },
        labels: {
          card: {
            title: 'Patinoires',
            splitTitle: 'Séparations de patinoire',
            splitSubtitle: 'Une patinoire peut être séparée pour permettre plusieurs événements simultanément',
          },
          alerts: {
            message: "Cette patinoire est une séparation de"
          },
          edit: 'Mettre à jour la patinoire',
          add: 'Nouvelle patinoire',
          name: 'Nom de la patinoire',
        },
        messages: {
          surfaces: 'patinoires',
          availability: {
            available: 'Cette patinoire %{input} disponible',
          }
        },
      },
      venues: {
        name: 'Aréna |||| Arénas',
        actions: {
          add: 'Ajouter un aréna',
          edit: "Modifier l'aréna",
        },
        labels: {
          name: "Nom de l'aréna",
          my: 'Mes arénas'
        },
        messages: {
          add_venues: 'Ajouter des arénas',
          search_venue: "Recherche par nom d'aréna ou par ville",
        },
        alerts: {
          loading_venues: 'Chargement des arénas...',
        }
      },
      activities: {
        fields: {
          arenaId: 'Patinoire'
        },
        alerts: {
          activity_explanation: 'Les activités sont uniquement des événements hors-glace, à ne pas confondre avec des matchs ou des pratiques.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Patinoire'
        }
      },
      events: {
        fields: {
          arenaId: 'Patinoire',
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Patinoire',
        },
        messages: {
          match_columns: 'Choissisez les équipes et les patinoires correspondants à vos colonnes sélectionnées.',
          publish_help: "La publication d'un brouillon rendra le match publique et donnera accès à la feuille de pointage. Les matchs publiés doivent avoir une date, une heure et une patinoire définies, et ils doivent disposer d'une séquence disponible pour le numéro de match.",
          confirm_clear_date: 'Êtes-vous sûr de vouloir supprimer la date de ce match brouillon? Cette action réinitialisera également les heures et la patinoire. |||| Êtes-vous sûr de vouloir supprimer la date de ces matchs brouillons? Cette action réinitialisera également les heures et la patinoire.',
          change_dates_dialog: 'Changer la date réinitialisera également les heures et la patinoire des brouillons.',
        },
      },
      games: {
        fields: {
          arenaId: 'Patinoire',
          effectiveSurfaces: 'Patinoire'
        },
        filters: {
          surfaceOffices: 'Organisation de la patinoire',
        },
        labels: {
          flags: {
            'Low Severity Penalties': 'Pénalité mineure |||| Pénalités mineures',
            'High Severity Penalties': 'Pénalité majeure |||| Pénalités majeures',
          }
        },
        messages: {
          group_games_by_arena: 'Grouper les matchs par patinoire',
        },
        alerts: {
          assignment: {
            suggestions: {
              officials_requested_this_game_already: 'Certains officiels ont demandé ce match ou sont déjà dans cet aréna',
              officials_already_arena: 'Certains officiels sont déjà dans cet aréna',
              no_officials_this_arena: "Aucun officiel n'est déjà dans cet aréna, mais certains sont prêts",
            },
            assigner: {
              travel: {
                another_venue: "Cet officiel sera <strong>dans un autre aréna</strong> dans l'heure qui suit ce match",
                this_venue: 'Cet officiel est assigné à cet aréna dans les 3 heures suivant ce match',
              },
              unavailable: {
                no_availability_arena: "L'officiel n'a pas inclus cet aréna dans ses paramètres de disponibilité",
              },
            },
            assignee: {
              travel: {
                another_venue: "Vous êtes <strong>assigné à un autre aréna</strong> dans l'heure qui suit ce match",
                this_venue: "Vous êtes assigné à cet aréna dans les 3 heures suivant ce match",
              },
              available: {
                assigned: "Votre calendrier indique que vous êtes disponible à cette heure et aréna",
              },
              unavailable: {
                no_availability_arena: "Vous n'avez pas inclus cet aréna dans vos paramètres de disponibilité",
              },
            }
          }
        },
        values: {
          status: {
            'Rink Changed': 'La patinoire de ce match a changé',
          },
          assignment: {
            position: {
              LinespersonTwo: 'Juge vidéo',
              LinespersonThree: 'Autre officiel hors glace',
            },
            position_short: {
              LinespersonTwo: 'JV',
              LinespersonThree: 'HG',
            }
          }
        }
      },
      participants: {
        labels: {
          availability: {
            card: {
              subtitle: "Sélectionnez les dates et les heures auxquelles vous êtes disponible pour arbitrer ou marquer des matchs, ou bloquez les heures où vous n'êtes pas disponible. Ouvrez les paramètres pour sélectionner vos assignation et vos arénas.",
            }
          }
        },
        messages: {
          availability: {
            select_venues_travel_to: "Sélectionnez les arénas dans lesquelles vous êtes prêt à vous rendre régulièrement. Si vous n'en sélectionnez aucun, vous serez disponible pour toutes les arénas.",
          }
        },
        alerts: {
          availability: {
            selection: {
              title: 'Disponible dans toutes les arénas',
              available_to_all_arenas: "Recherchez ci-dessus si vous souhaitez éventuellement limiter les arénas auxquelles vous êtes disponible.",
            },
          }
        }
      },
      qualifications: {
        validations: {
          level: 'Doit être supérieur ou égal à 0. "M9" correspond au niveau 0.'
        },
        values: {
          level: {
            zero: 'M9',
            level_zero: 'M9',
          }
        }
      },
      assignSettings: {
        validations: {
          min_setting_range: 'Doit être supérieur ou égal à 0. "M9" correspond au niveau 0.',
        }
      },
      assignFees: {
        fields: {
          LinespersonTwo: 'Juge vidéo',
          LinespersonThree: 'Autre officiel hors glace',
        }
      },
      assignSystems: {
        fields: {
          linespersonsTwo: 'Juge vidéo',
          linespersonsThree: 'Autre officiel hors glace',
        }
      },
      officials: {
        filters: {
          ignore_arena: "Ignorer les disponibilités de l'aréna",
        }
      },
      officialConflicts: {
        labels: {
          card: {
            subtitle: "Les matchs suivants sont programmés en même temps avec les mêmes équipes ou le même aréna. Si aucun match actif n'est répertorié, le statut de ce match peut être changé en Actif.",
          }
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Patinoires'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Patinoires'
          }
        }
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Patinoires'
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Patinoires'
          }
        }
      },
      officeSettings: {
        fields: {
          skaterStatsHiddenDivisionIds: 'Division à cacher pour les statistiques des patineurs',
          gameLength: 'Temps de réservation de la patinoire',
          surfaceOfficeId: "L'organisation des patinoires"
        },
        labels: {
          stats_schema: {
            skater: 'Schéma des statistiques de patineur',
          },
          standings_order: {
            skater: 'Ordre du classement des patineurs',
            diff: 'Différentiel de but',
            minutesPlayed: 'Temps de glace',
          },
          hidden_stats: {
            skater: 'Statistiques cachées des patineurs',
          },
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur la patinoire pour tous les matchs de cet horaire",
          surfaceOfficeId: "Optionnel - Les patinoires proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        },
        values: {
          stats: {
            skater: 'Statistiques des patineurs',
          }
        }
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Taille des patinoires',
          gameLength: 'Temps de réservation de la patinoire',
          surfaceOfficeId: "L'organisation des patinoires"
        },
        labels: {
          stats_schema: {
            skater: 'Schéma des statistiques de patineur',
          },
          standings_order: {
            skater: 'Ordre du classement des patineurs',
          },
          hidden_stats: {
            skater: 'Statistiques cachées des patineurs',
          },
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur la patinoire pour tous les matchs de cet horaire",
          surfaceOfficeId: "Optionnel - Les patinoires proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        },
      },
      teams: {
        fields: {
          arenaId: 'Patinoire'
        }
      },
      teamArenas: {
        name: 'Patinoire à domicile |||| Patinoires à domicile',
        fields: {
          arenaId: 'Patinoire'
        },
        actions: {
          edit: 'Mettre à jour la patinoire à domicile'
        },
        labels: {
          card: {
            title: 'Patinoires à domicile',
            subtitle: "Les patinoires à domicile de l'équipe seront utilisées lors de la création d'un horaire."
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Patinoire'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Patinoire'
        },
        labels: {
          card: {
            subtitle: 'Enregistrez vos réservations de patinoire pour voir vos plages disponible'
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Conflits de patinoire',
        }
      },
      drafts: {
        fields: {
          columns: {
            arenaId: 'Patinoire'
          }
        },
        labels: {
          no_venue: 'Pas de patinoire'
        },
        messages: {
          share: {
            messages: {
              info: 'Les associations ne pourront modifier que les temps et les patinoires de leurs propres matchs et ne pourront pas publier le brouillon.'
            }
          }
        }
      },
      rulebooks: {
        labels: {
          skaters: 'Patineurs',
        },
        values: {
          lineup_variances: {
            HC: 'Patineur supplémentaire pour M18 AAA, Junior, Sénior',
          },
        }
      },
      scoresheets: {
        labels: {
          clock: 'Temps',
          penalty: {
            game_time: 'Temps',
            start_time: 'Début'
          },
          goal: {
            game_time: 'Temps',
          }
        },
        alerts: {
          requirements: {
            skaters: {
              min: 'Minimum de %{min} patineurs',
              max: "Maximum de %{max} patineurs",
            }
          }
        }
      }
    },
    components: {
      calendar: {
        messages: {
          no_home_surface: "Aucune patinoire locale n'a été configurée pour cette équipe",
          no_result: 'Aucune patinoire trouvée avec le filtre de taille de surface sélectionné',
          remove_time_field: "Êtes-vous sûr de vouloir supprimer l'heure et la patinoire de ce match?",
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Patinoire"
              }
            }
          }
        }
      }
    }
  },
  Soccer: {
    ra: {
      action: {
        certify: 'Homologuer',
      }
    },
    resources: {
      surfaces: {
        name: 'Terrain |||| Terrains',
        values: {
          size: {
            XL: 'XL (11x11)',
            L: 'L (11x11 ou 9x9)',
            M: 'M (7x7 ou 9x9)',
            S: 'S (7x7)',
            XS: 'XS (4x4)'
          },
        },
        actions: {
          add: 'Ajouter un terrain',
          edit: 'Modifier le terrain',
        },
        labels: {
          card: {
            title: 'Terrains',
            splitTitle: 'Séparations de terrain',
            splitSubtitle: 'Un terrain peut être séparé pour permettre plusieurs événements simultanément',
          },
          alerts: {
            message: "Ce terrain est une séparation de"
          },
          edit: 'Mettre à jour le terrain',
          add: 'Nouveau terrain',
          name: 'Nom du terrain',
        },
        messages: {
          surfaces: 'terrains',
          availability: {
            available: 'Ce terrain %{input} disponible',
          }
        },
      },
      activities: {
        fields: {
          arenaId: 'Terrain'
        },
        alerts: {
          activity_explanation: 'Les activités sont uniquement des événements hors-terrain, à ne pas confondre avec des matchs ou des pratiques.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      events: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      groups: {
        values: {
          type: {
            'League': 'Championnat',
          }
        }
      },
      schedules: {
        values: {
          type: {
            'League': 'Championnat',
          }
        }
      },
      schedulingConstraints: {
        values: {
          scheduleTypes: {
            'League': 'Championnat',
          }
        }
      },
      assignRules: {
        values: {
          types: {
            'League': 'Championnat',
          },
          options: {
            minLinesperson: "Niveau d'arbitre assistant",
            minOfficial: 'Niveau de 4ème arbitre',
          },
          labels: {
            rules: {
              minLinesperson: "Niveau d'arbitre assistant: %{value}",
              minOfficial: 'Niveau de 4ème arbitre: %{value}',
            }
          }
        }
      },
      reports: {
        values: {
          scheduleType: {
            'League': 'Championnat',
          }
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Terrain',
        },
        messages: {
          match_columns: 'Choissisez les équipes et les terrains correspondants à vos colonnes sélectionnées.',
          publish_help: "La publication d'un brouillon rendra le match publique et donnera accès à la feuille de pointage. Les matchs publiés doivent avoir une date, une heure et un terrain définies, et ils doivent disposer d'une séquence disponible pour le numéro de match.",
          confirm_clear_date: 'Êtes-vous sûr de vouloir supprimer la date de ce match brouillon? Cette action réinitialisera également les heures et le terrain. |||| Êtes-vous sûr de vouloir supprimer la date de ces matchs brouillons? Cette action réinitialisera également les heures et le terrain.',
          change_dates_dialog: 'Changer la date réinitialisera également les heures et le terrain des brouillons.',
        },
      },
      games: {
        fields: {
          arenaId: 'Terrain',
          effectiveSurfaces: 'Terrain'
        },
        filters: {
          surfaceOffices: 'Organisation du terrain',
          isCertified: 'Homologuée'
        },
        messages: {
          group_games_by_arena: 'Grouper les matchs par terrain',
        },
        values: {
          status: {
            'Rink Changed': 'Le terrain de ce match a changé',
          },
          assignment: {
            position: {
              Linesperson: 'Assistant 1',
              LinespersonTwo: 'Assistant 2',
              LinespersonThree: 'Assistant 3',
              Official: '4ème arbitre'
            },
            position_short: {
              Linesperson: 'A1',
              LinespersonTwo: 'A2',
              LinespersonThree: 'A3'
            }
          }
        },
        labels: {
          game_number_certify: 'Homologation du Match %{number}',
          certify_game: 'Homologuer le match',
          certification: 'Homologation',
        },
        notifications: {
          certified_games: '%{smart_count} match homologué |||| %{smart_count} matchs homologués',
          certified_games_error: "Une erreur s'est produite lors de l'homologation' du match |||| Une erreur s'est produite lors de l'homologation' des matchs",
        }
      },
      gameEvents: {
        messages: {
          game_certified: 'Feuille de match homologuée'
        },
        values: {
          eventType: {
            scoresheetCertified: 'Feuille de match homologuée',
            penaltyStarted: 'Carton ajoutée',
            penaltyEdited: 'Carton mise à jour',
            penaltyDeleted: 'Carton supprimée',
          }
        }
      },
      gameAssignSettings: {
        fields: {
          minLinesperson: "Niveau minimum d'arbitre assistant",
          minOfficial: 'Niveau minimum de 4ème arbitre',
        },
        labels: {
          linesperson: "Niveau de l'assistant",
          official: 'Niveau de 4ème arbitre'
        }
      },
      participants: {
        labels: {
          availability: {
            add_associations: 'Ajouter des organisations',
          }
        },
        messages: {
          availability: {
            search_by_association: "Recherche par nom d'organisation",
            select_associations: 'Sélectionnez les organisations pour lesquelles vous vous rendrez disponible pour arbitrer ou marquer des matchs.',
          }
        },
        alerts: {
          availability: {
            loading_associations: 'Chargement des organisations...',
            no_associations: {
              title: 'Aucunne organisation sélectionnée',
              search_make_yourself_available: "Faites une recherche ci-dessus pour vous mettre disponible pour une organisation.",
              not_available_to_assigners: "Vous ne serez pas disponible pour les assignateurs ou ne pourrez voir aucun match tant que vous n'aurez pas sélectionné une organisation.",
            }
          }
        }
      },
      assignFees: {
        fields: {
          Linesperson: 'Assistant 1',
          LinespersonTwo: 'Assistant 2',
          LinespersonThree: 'Assistant 3',
          Official: '4ème arbitre'
        }
      },
      assignSystems: {
        fields: {
          linespersons: 'Assistants 1',
          linespersonsTwo: 'Assistants 2',
          linespersonsThree: 'Assistants 3',
          officials: '4ème arbitres'
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
        values: {
          type: {
            Association: 'Club',
            Club: 'Coupe'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      officeSettings: {
        fields: {
          skaterStatsHiddenDivisionIds: 'Division à cacher pour les statistiques des joueurs de champ',
          gameLength: 'Temps de réservation du terrain',
          surfaceOfficeId: "L'organisation des terrains",
          requireCertification: 'Homolgation des matchs',
        },
        labels: {
          stats_schema: {
            skater: 'Schéma des statistiques de joueur de champ',
          },
          standings_order: {
            skater: 'Ordre du classement des joueurs de champ',
            penaltyOccurrences: 'Nombre Total de Cartons',
            diff: 'Différentiel de but',
          },
          hidden_stats: {
            skater: 'Statistiques cachées des joueurs de champ',
          },
          certification: 'Homologation'
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur le terrain pour tous les matchs de cet horaire",
          surfaceOfficeId: "Optionnel - Les terrains proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        },
        values: {
          stats: {
            skater: 'Statistiques des joueurs de champ',
          },
          suspension_activation: {
            certification: 'Après match homologué',
          }
        }
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Taille des terrains',
          gameLength: 'Temps de réservation du terrain',
          surfaceOfficeId: "L'organisation des terrains",
        },
        labels: {
          stats_schema: {
            skater: 'Schéma des statistiques de joueur de champ',
          },
          standings_order: {
            skater: 'Ordre du classement des joueurs de champ',
          },
          hidden_stats: {
            skater: 'Statistiques cachées des joueurs de champ',
          },
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur le terrain pour tous les matchs de cet horaire",
          includeRegistrations: "Les participants inscrits en fonction du club, de la division et de la saison de l'équipe seront inclus comme options d'alignement.",
          surfaceOfficeId: "Optionnel - Les terrains proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        }
      },
      teams: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      teamArenas: {
        name: 'Terrain à domicile |||| Terrains à domicile',
        fields: {
          arenaId: 'Terrain'
        },
        actions: {
          edit: 'Mettre à jour le terrain à domicile'
        },
        labels: {
          card: {
            title: 'Terrains à domicile',
            subtitle: "Les terrains à domicile de l'équipe seront utilisées lors de la création d'un horaire."
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Terrain'
        },
        labels: {
          card: {
            subtitle: 'Enregistrez vos réservations de terrain pour voir vos plages disponibles'
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Conflits de terrain',
        }
      },
      profiles: {
        alerts: {
          private_profile: "Ce profil n'apparaîtra qu'aux administrateurs autorisés de la ligue et du club et n'apparaîtra pas sur le site Web de la ligue.",
        }
      },
      drafts: {
        fields: {
          columns: {
            arenaId: 'Terrain'
          }
        },
        labels: {
          no_venue: 'Pas de terrain'
        },
        messages: {
          share: {
            messages: {
              list: 'Ce brouillon sera partagé avec les clubs suivants:',
              info: 'Les clubs ne pourront modifier que les temps et les lieux de leurs propres matchs et ne pourront pas publier le brouillon.'
            }
          }
        }
      },
      rulebooks: {
        labels: {
          skaters: 'Joueurs de champ',
        },
        values: {
          lineup_variances: {
            HC: 'Joueurs de champ supplémentaire pour M18 AAA, Junior, Sénior',
          },
        }
      },
      help: {
        values: {
          roles: {
            association: 'Clubs'
          }
        }
      },
      scoresheets: {
        actions: {
          add_penalties: 'Cartons',
        },
        labels: {
          add_penalties: 'Ajouter des cartons',
          certify: 'Homologuer le match |||| Homologuer les matchs',
          undo_certify: {
            title: 'Déshomologuer la feuille de match'
          },
          clock: 'Minute',
          penalty: {
            game_time: 'Minute'
          },
          goal: {
            game_time: 'Minute',
          },
          home: {
            penalty: 'Carton - Local',
          },
          away: {
            penalty: 'Carton - Visiteur',
          },
        },
        messages: {
          end_game: "À quelle minute la partie s'est-elle terminée?",
          confirm_certify: 'Voulez-vous vraiment homologuer ce match? |||| Voulez-vous vraiment homologuer ces matchs?',
          successful_game_certify: 'Match Homologué',
        },
        alerts: {
          requirements: {
            skaters: {
              min: 'Minimum de %{min} joueurs de champ',
              max: "Maximum de %{max} joueurs de champ",
            }
          },
          not_certified: "Cette feuille de match n'a pas été homologuée.",
          certified: 'Feuille de match homologuée.'
        },
      },
      gamepenalties: {
        name: 'Cartons',
        fields: {
          gameTime: 'Minute',
        },
      },
      suspensions: {
        fields: {
          penaltyId: 'Carton',
        }
      },
      gameIncidents: {
        fields: {
          penaltyId: 'Carton',
        },
      },
      members: {
        values: {
          positions: {
            'F': 'Joueur',
          }
        }
      }
    },
    components: {
      login: {
        validation: {
          game_already_completed_long: 'Ce match est déjà terminé. Pour les corrections, veuillez contacter votre club.',
        }
      },
      calendar: {
        messages: {
          no_home_surface: "Aucun terrain locale n'a été configurée pour cette équipe",
          no_result: 'Aucun terrain trouvée avec le filtre de taille de surface sélectionné',
          remove_time_field: "Êtes-vous sûr de vouloir supprimer l'heure et le terrain de ce match?",
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Terrain"
              }
            }
          }
        },
        penalties_exporter: {
          reports: {
            summary: {
              columns: {
                game_time: 'Minute'
              }
            }
          },
        }
      }
    }
  },
  Baseball: {
    resources: {
      surfaces: {
        name: 'Terrain |||| Terrains',
        values: {
          size: {
            XL: 'XL (18U+)',
            L: 'L (15U)',
            M: 'M (13U)',
            S: 'S (11U)',
            XS: 'XS (9U)'
          },
        },
        actions: {
          add: 'Ajouter un terrain',
          edit: 'Modifier le terrain',
        },
        labels: {
          card: {
            title: 'Terrains',
            splitTitle: 'Séparations de terrain',
            splitSubtitle: 'Un terrain peut être séparé pour permettre plusieurs événements simultanément',
          },
          alerts: {
            message: "Ce terrain est une séparation de"
          },
          edit: 'Mettre à jour le terrain',
          add: 'Nouveau terrain',
          name: 'Nom du terrain',
        },
        messages: {
          surfaces: 'terrains',
          availability: {
            available: 'Ce terrain %{input} disponible',
          }
        },
      },
      activities: {
        fields: {
          arenaId: 'Terrain'
        },
        alerts: {
          activity_explanation: 'Les activités sont uniquement des événements hors-terrain, à ne pas confondre avec des matchs ou des pratiques.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      events: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      schedules: {
        values: {
          type: {
            'League': 'Saison régulière',
          }
        }
      },
      schedulingConstraints: {
        values: {
          scheduleTypes: {
            'League': 'Saison régulière',
          }
        }
      },
      assignRules: {
        values: {
          types: {
            'League': 'Saison régulière',
          }
        },
        options: {
          minReferee: "Niveau de l'arbitre au marbre",
          minLinesperson: "Niveau de l'arbitre sur les buts",
        },
        labels: {
          rules: {
            minReferee: "Niveau de l'arbitre au marbre: %{value}",
            minLinesperson: "Niveau de l'arbitre sur les buts: %{value}",
          }
        }
      },
      reports: {
        values: {
          scheduleType: {
            'League': 'Saison régulière',
          }
        }
      },
      seasons: {
        values: {
          seasonId: {
            '2025-26': '2025',
            '2024-25': '2024',
            '2023-24': '2023',
            '2022-23': '2022',
            '2021-22': '2021',
            '2020-21': '2020',
            '2019-20': '2019',
            '2018-19': '2018',
          }
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Terrain',
        },
        messages: {
          match_columns: 'Choissisez les équipes et les terrains correspondants à vos colonnes sélectionnées.',
          publish_help: "La publication d'un brouillon rendra le match publique et donnera accès à la feuille de pointage. Les matchs publiés doivent avoir une date, une heure et un terrain définies, et ils doivent disposer d'une séquence disponible pour le numéro de match.",
          confirm_clear_date: 'Êtes-vous sûr de vouloir supprimer la date de ce match brouillon? Cette action réinitialisera également les heures et le terrain. |||| Êtes-vous sûr de vouloir supprimer la date de ces matchs brouillons? Cette action réinitialisera également les heures et le terrain.',
          change_dates_dialog: 'Changer la date réinitialisera également les heures et le terrain des brouillons.',
        },
      },
      games: {
        fields: {
          arenaId: 'Terrain',
          effectiveSurfaces: 'Terrain'
        },
        filters: {
          surfaceOffices: 'Organisation du terrain',
        },
        labels: {
          flags: {
            'High Severity Penalties': 'Expulsion |||| Expulsions',
          }
        },
        messages: {
          group_games_by_arena: 'Grouper les matchs par terrain',
        },
        values: {
          status: {
            'Rink Changed': 'Le terrain de ce match a changé',
          },
          assignment: {
            position: {
              Referee: 'Arbitre au marbre',
              Linesperson: 'Arbitre sur les buts',
              LinespersonTwo: 'Arbitre au 2e but',
              LinespersonThree: 'Arbitre au 3e but',
            },
            position_short: {
              Referee: 'AM',
              Linesperson: 'AB',
              LinespersonTwo: 'AB2',
              LinespersonThree: 'AB3',
            }
          }
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Terrains'
          }
        },
      },
      officeSettings: {
        fields: {
          gameLength: 'Temps de réservation du terrain',
          surfaceOfficeId: "L'organisation des terrains",
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur le terrain pour tous les matchs de cet horaire",
          surfaceOfficeId: "Optionnel - Les terrains proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
          incidentReports: "Les matchs nécessiteront des rapports d'incident pour tous les expulsions avant d'être terminés"
        },
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Taille des terrains',
          gameLength: 'Temps de réservation du terrain',
          surfaceOfficeId: "L'organisation des terrains",
        },
        helpers: {
          gameLength: "Optionnel - Temps de réservation total (en minutes) sur le terrain pour tous les matchs de cet horaire",
          surfaceOfficeId: "Optionnel - Les terrains proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        }
      },
      teams: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      teamArenas: {
        name: 'Terrain à domicile |||| Terrains à domicile',
        fields: {
          arenaId: 'Terrain'
        },
        actions: {
          edit: 'Mettre à jour le terrain à domicile'
        },
        labels: {
          card: {
            title: 'Terrains à domicile',
            subtitle: "Les terrains à domicile de l'équipe seront utilisés lors de la création d'un horaire."
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Terrain'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Terrain'
        },
        labels: {
          card: {
            subtitle: 'Enregistrez vos réservations de terrain pour voir vos plages disponibles'
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Conflits de terrain',
        }
      },
      drafts: {
        values: {
          draft_type: {
            'Single Rotation': 'Une partie par étape',
            'Double Rotation': 'Deux parties par étape',
          },
        },
        fields: {
          columns: {
            arenaId: 'Terrain'
          }
        },
        labels: {
          no_venue: 'Pas de terrain'
        },
      },
      gameAssignSettings: {
        fields: {
          minReferee: "Niveau minimum de l'arbitre au marbre",
          minLinesperson: "Niveau minimum de l'arbitre sur les buts",
        },
        labels: {
          referee: "Niveau de l'arbitre au marbre",
          linesperson: "Niveau de l'arbitre sur les buts",
        }
      },
      assignFees: {
        fields: {
          Referee: 'Arbitre au marbre',
          Linesperson: 'Arbitre sur les buts',
          LinespersonTwo: 'Arbitre au 2e but',
          LinespersonThree: 'Arbitre au 3e but',
        }
      },
      assignSystems: {
        fields: {
          referees: 'Arbitre au marbre',
          linespersons: 'Arbitre sur les buts',
          linespersonsTwo: 'Arbitre au 2e but',
          linespersonsThree: 'Arbitre au 3e but',
        }
      },
      scoresheets: {
        actions: {
          add_penalties: 'Expulsions',
        },
        labels: {
          add_penalties: 'Ajouter des expulsions',
          home: {
            penalty: 'Expulsion - Local',
          },
          away: {
            penalty: 'Expulsion - Visiteur',
          }
        },
        messages: {
          end_game: "A quelle manche la partie a t'elle terminée?",
        },
        values: {
          half: {
            first: 'Début',
            second: 'Fin'
          }
        },
        alerts: {
          missingIncidents: {
            message: "Certains expulsions nécessitent un rapport d'incident qui doit être soumis avant que le match ne puisse être terminé.",
          }
        }
      },
      suspensions: {
        fields: {
          penaltyId: 'Expulsion',
        },
      },
      gameIncidents: {
        fields: {
          penaltyId: 'Expulsion',
        },
        labels: {
          penaltyId: 'Expulsion',
        },
      },
      gamepenalties: {
        name: 'Expulsion |||| Expulsions',
      },
      members: {
        values: {
          positions: {
            'F': 'Joueur',
          }
        }
      },
      notifications: {
        fields: {
          majorPenalty: 'Expulsions',
        },
        helpers: {
          majorPenalty: 'Les feuilles de match avec les expulsions seront envoyées après la fin du match',
        }
      }
    },
    components: {
      calendar: {
        messages: {
          no_home_surface: "Aucun terrain locale n'a été configurée pour cette équipe",
          no_result: 'Aucun terrain trouvée avec le filtre de taille de surface sélectionné',
          remove_time_field: "Êtes-vous sûr de vouloir supprimer l'heure et le terrain de ce match?",
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Terrain"
              }
            }
          }
        }
      }
    }
  }
}

export default messages;
